<template>
  <LoginForm />
</template>

<script>
// @ is an alias to /src
import LoginForm from "@/components/Login/LoginForm.vue";

export default {
  name: "Login",
  components: {
    LoginForm
  }
};
</script>
