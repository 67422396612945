<template>
  <AdminLoginForm />
</template>

<script>
// @ is an alias to /src
import AdminLoginForm from "@/components/Login/AdminLoginForm.vue";

export default {
  name: "AdminLogin",
  components: {
    AdminLoginForm
  }
};
</script>
