var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-container',{staticStyle:{"background-color":"black"},attrs:{"fill-height":"","fill-width":"","fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',[_c('center',[_c('v-card',{staticClass:"rounded-circle",style:('border: 6px solid #00A99E; background-color:black; width:70%; height:70%')},[_c('v-col',{staticClass:"d-flex flex-column justify-center align-center"},[_c('v-img',{staticStyle:{"margin-bottom":"5%","margin-top":"3%","margin-right":"5%","margin-left":"5%"},attrs:{"src":require('../../assets/sparky.png'),"height":"90%","width":"90%","border-radius":"100%","outl":""}})],1)],1)],1),_c('div',{staticStyle:{"display":"block","background-color":"black","height":"30px"}}),_c('h1',{staticStyle:{"color":"#00A99E","font-weight":"900"}},[_c('span',{staticStyle:{"color":"white","font-weight":"900"}},[_vm._v("Welcome to ")]),_vm._v("SPARK! ")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('v-container',{attrs:{"fill-height":"","fluid":""}},[(_vm.view == 0)?_c('v-col',{staticClass:"d-flex flex-column justify-center align-center"},[_c('GoogleLoginButton',{attrs:{"buttonLabel":"Sign in with Google","hide":"true"}}),_c('v-row',[_c('v-col',[_c('v-hover',{attrs:{"close-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"mx-auto rounded-xl",class:{ 'on-hover': hover },style:(_vm.roleSelected == 'admin'
                      ? 'background-color:white; border: 5px solid #00A99e;'
                      : 'background-color:#e5ede6; border: none;'),attrs:{"elevation":hover ? 16 : 0,"min-height":"300","max-width":"270","id":"admin"},nativeOn:{"click":function($event){return _vm.clickedRole('admin')}}},[_c('AdminIcon',{staticClass:"icon",style:(_vm.roleSelected == 'admin'
                        ? 'margin-right:115px; margin-left:-30px'
                        : ''),attrs:{"id":"adminIcon"}}),_c('v-card-text',{staticClass:"text",style:(_vm.roleSelected == 'admin'
                        ? 'visibility:visible; opacity: 1;'
                        : ''),attrs:{"id":"adminText"}},[_vm._v(" Hey admin! Check up on your Spark! dashboard with me. ")]),_c('div',{staticStyle:{"display":"block","height":"10px"}}),_c('v-card-text',{staticStyle:{"font-weight":"900","font-size":"25px","color":"black"}},[_vm._v(" Admin ")])],1)]}}],null,false,1638722268)})],1),_c('v-col',[_c('v-hover',{attrs:{"close-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"mx-auto rounded-xl",class:{ 'on-hover': hover },style:(_vm.roleSelected == 'student'
                      ? 'background-color:white; border: 5px solid #00A99e;'
                      : 'background-color:#e5ede6; border: none;'),attrs:{"elevation":hover ? 16 : 0,"min-height":"300","max-width":"270","id":"student"},nativeOn:{"click":function($event){return _vm.clickedRole('student')}}},[_c('StudentIcon',{staticClass:"icon",style:(_vm.roleSelected == 'student' ? 'margin-left: 100px;' : ''),attrs:{"id":"studentIcon"}}),_c('v-card-text',{staticClass:"text",style:(_vm.roleSelected == 'student'
                        ? 'visibility:visible; opacity: 1;'
                        : ''),attrs:{"id":"studentText"}},[_vm._v(" Hey student! I'll help you apply to Spark! ")]),_c('div',{staticStyle:{"display":"block","height":"10px"}}),_c('v-card-text',{staticStyle:{"font-weight":"900","font-size":"25px","color":"black"}},[_vm._v(" Student ")])],1)]}}],null,false,4201270647)})],1)],1),_c('div',{staticStyle:{"display":"block","height":"50px"}}),_c('v-btn',{staticClass:"ma-4 pl-16 pr-16 white--text bold--text",staticStyle:{"font-weight":"900","font-size":"20px"},attrs:{"x-large":"","color":"#00A99E","rounded":""},on:{"click":function($event){return _vm.updateView(1)}}},[_vm._v(" CONTINUE ")])],1):_vm._e(),(_vm.view == 1 && _vm.roleSelected == 'student')?_c('v-col',{staticClass:"d-flex flex-column justify-center align-center"},[_c('h1',[_vm._v("Student sign in")]),_c('GoogleLoginButton',{attrs:{"buttonLabel":"Sign in with Google"}}),_c('v-btn',{staticClass:"ma-4",on:{"click":function($event){return _vm.updateView(0)}}},[_vm._v(" Back ")])],1):_vm._e(),(_vm.view == 1 && _vm.roleSelected == 'admin')?_c('v-col',{staticClass:"d-flex flex-column justify-center align-center"},[_c('h1',[_vm._v("Admin sign in")]),(_vm.errorMsg)?_c('v-alert',[_vm._v(" "+_vm._s(_vm.errorMsg))]):_vm._e(),_c('h3',[_vm._v("Login")]),_c('h4',[_c('GoogleLoginButton',{attrs:{"buttonLabel":"Log in with Google"}})],1),_c('br'),_c('h3',[_vm._v("Sign Up")]),_c('v-btn',{staticClass:"ma-4",attrs:{"color":"#36bd90","rounded":""},on:{"click":function($event){return _vm.updateView(2)}}},[_vm._v(" Admin Sign Up ")]),_c('v-btn',{staticClass:"ma-4",on:{"click":function($event){return _vm.updateView(0)}}},[_vm._v(" Back ")])],1):_vm._e(),(_vm.view == 2 && _vm.roleSelected == 'admin')?_c('v-col',{staticClass:"d-flex flex-column justify-center align-center"},[_c('AdminLogin')],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }